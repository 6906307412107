<template>
  <NuxtLink :to="`/social/${social.code || ''}`"
    class="p-0 md:p-4 rounded-xl md:bg-zinc-900 md:hover:bg-zinc-800 will-change-[background] transition-colors cursor-pointer min-w-[8rem] snap-center flex flex-col"
    @click="acitve = true">
    <!-- cover -->
    <div class="mx-auto m-0 w-full relative rounded-xl shadow-xl grid place-content-center overflow-hidden" :class="{
      'social-cover': acitve,
    }">
      <img :src="cover" @error="e => { e.target.src = '/images/cover.png' }"
        class="shadow-lg h-full w-full aspect-square object-top object-cover select-none rounded-xl overflow-hidden" />
    </div>

    <!-- title -->
    <div class="mt-2 md:mt-4 min-h-0 md:min-h-[62px]">
      <div class="flex gap-1 font-mono text-zinc-400">
        <GlobeAltIcon class="size-4 flex-none" />
        <p class="text-xs truncate">{{ social.code || '-' }}</p>
      </div>
      <p class="pb-1 text-sm md:text-base font-normal md:font-bold !text-white truncate" :class="{
        'social-name': acitve,
      }">
        {{ social.title || '-' }}
      </p>
      <p class="text-xs md:text-sm font-mono text-zinc-400 truncate">
        {{ social.publishAt ? formatTime(social.publishAt, 'YYYY-MM-DD') : '-' }}
      </p>
    </div>
  </NuxtLink>
</template>

<script setup>
import { GlobeAltIcon } from '@heroicons/vue/24/solid'
const { formatTime } = useTool()
const { social } = defineProps({
  social: {
    type: Object,
    default: {},
  },
})

const cover = computed(() => {
  if (social?.cover) return social.cover
  else if (social?.cardImages?.length > 0) return social.cardImages[0]
  else return '/images/cover.png'
})

const acitve = ref(false)
</script>

<style scoped>
.social-cover {
  view-transition-name: social-cover;
}

.social-name {
  view-transition-name: social-name;
}
</style>
